import { AxiosError, AxiosResponse } from 'axios';

/** Services */
import { HttpClient } from '@services/http-client';

/** Models */
import { BackResponse } from '@models/back-response.model';
import { DcaPairs } from '@models/request-get-dca-pairs.model';
import { RequestSimulatorDCA } from '@models/request/request-dca-simulator.model';
import { ResponseSimulatorDCA } from '@models/request/response-dca-simulator.model';

/** Root */
import { asyncWrap } from '@root/util';

export class DCAService extends HttpClient {
  private readonly routePrefix = 'dca';

  async getDcaPairs(): Promise<DcaPairs | undefined> {
    try {
      const route = `${this.routePrefix}/available-pairs`;
      const response = await asyncWrap<AxiosResponse<BackResponse<DcaPairs>>>(
        this.axiosInstance.get<BackResponse<DcaPairs>>(route),
      );

      if (!response || !response.result) {
        return;
      }

      return response.result && response.result.data.data
        ? response.result.data.data
        : undefined;
    } catch (err) {
      return {
        tokenList: [],
        stableList: [],
        mapSymbolName: {},
      };
    }
  }

  async getDcaSimulatorResult(
    requestSimulatorDca: RequestSimulatorDCA,
  ): Promise<BackResponse<ResponseSimulatorDCA>> {
    try {
      const route = `${this.routePrefix}/simulator`;
      const { data: result } = await this.axiosInstance.post<
        BackResponse<ResponseSimulatorDCA>
      >(route, requestSimulatorDca);

      return result;
    } catch (err) {
      return this.getError(err as AxiosError<BackResponse<any>, any>);
    }
  }
}
