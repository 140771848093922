import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { Dictionary, Market, OHLCV, Ticker } from 'ccxt';

/** Root */
import { asyncWrap } from '@root/util';

export class ApiExchange {
  private axiosInstance: AxiosInstance;
  private _markets: { [key: string]: Market };

  constructor(private readonly exchangeName: string) {
    this._markets = {};
    this.axiosInstance = axios.create({
      baseURL: `${process.env.GATSBY_API_URL}/exchange`,
    });
  }

  get markets(): { [key: string]: Market } {
    return this._markets;
  }

  get name(): string {
    return this.exchangeName;
  }

  async loadMarkets(reload = false): Promise<Dictionary<Market>> {
    const marketsLength = !this._markets
      ? 0
      : Object.keys(this._markets).length;

    if ((!reload && !marketsLength) || reload) {
      const response = await asyncWrap<AxiosResponse<Dictionary<Market>>>(
        this.axiosInstance.get<Dictionary<Market>>(
          `/${this.exchangeName}/markets`,
        ),
      );

      if (!response || !response.result) {
        return {};
      }

      this._markets = response.result.data;
    }

    return this._markets;
  }

  async fetchTicker(symbol: string, cache = true): Promise<Ticker> {
    const url = cache
      ? `/${this.exchangeName}/ticker/${symbol.replace('/', '_')}`
      : `/${this.exchangeName}/ticker/update/${symbol.replace('/', '_')}`;

    const response = await asyncWrap<AxiosResponse<Ticker>>(
      this.axiosInstance.get<Ticker>(url),
    );

    if (!response || !response.result) {
      return this.getEmptyTicker(symbol);
    }

    return response.result.data;
  }

  async ping(): Promise<boolean> {
    const response = await asyncWrap<AxiosResponse<boolean>>(
      this.axiosInstance.get<boolean>(`/${this.exchangeName}/ping`),
    );

    if (response && response.result) {
      return response.result.data;
    }

    return false;
  }

  async fetchOHLCV(symbol: string, interval: string): Promise<OHLCV[]> {
    const response = await asyncWrap<AxiosResponse<OHLCV[]>>(
      this.axiosInstance.get<OHLCV[]>(
        `/${this.exchangeName}/ohlcv/${symbol.replace('/', '_')}/${interval}`,
      ),
    );

    if (response && response.result) {
      return response.result.data;
    }

    return [];
  }

  protected getEmptyTicker(symbol: string): Ticker {
    return {
      symbol,
      ask: 0,
      askVolume: 0,
      bid: 0,
      bidVolume: 0,
      open: 0,
      close: 0,
      last: 0,
      high: 0,
      low: 0,
      timestamp: Date.now(),
      datetime: new Date().toISOString(),
      info: {},
    };
  }
}
