/** Root */
import { ApiExchange } from '@root/exchanges/api.exchange';
import { BinanceExchange } from '@root/exchanges/binance.exchange';

export class Exchange {
  static get(): ApiExchange {
    return Exchange.binanceInstance;
  }

  static async init(): Promise<void> {
    if (this.binanceInstance) {
      return;
    }

    this.binanceInstance = new BinanceExchange();

    await this.binanceInstance.loadMarkets(true);
  }

  private static binanceInstance: BinanceExchange;
}
