import axios from 'axios';
import { Ticker } from 'ccxt';

/** Models */
import { ExchangeType } from '@models/exchange-type.enum';

/** Root */
import { asyncWrap } from '@root/util';

/** Exchanges */
import { ApiExchange } from './api.exchange';

export class BinanceExchange extends ApiExchange {
  private readonly apiUrl = 'https://api.binance.com/api/v3/';

  constructor() {
    super(ExchangeType.Binance);
  }

  async fetchTicker(symbol: string): Promise<Ticker> {
    const url = `${this.apiUrl}ticker/24hr?symbol=${symbol.replace('/', '')}`;
    const fetchTicker = await asyncWrap(axios.get(url));

    if (!fetchTicker || !fetchTicker.result) {
      return this.getEmptyTicker(symbol);
    }

    const response = fetchTicker.result.data as any;
    const ticker: Ticker = {
      symbol,
      ask: Number(response.askPrice),
      askVolume: Number(response.askQty),
      bid: Number(response.bidPrice),
      bidVolume: Number(response.bidQty),
      open: Number(response.openPrice),
      close: Number(response.lastPrice),
      last: Number(response.lastPrice),
      high: Number(response.highPrice),
      low: Number(response.lowPrice),
      timestamp: Date.now(),
      datetime: new Date().toISOString(),
      info: response,
    };

    return ticker;
  }

  async ping(): Promise<boolean> {
    const response = await asyncWrap(axios.get(`${this.apiUrl}ping`));

    if (!response) {
      return false;
    }

    return true;
  }
}
