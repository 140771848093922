import React from 'react';
import { Trans } from 'react-i18next';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const DcaInfo = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div className="row mt-5 d-flex">
      <div className="col-12 col-sm-5 d-flex">
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-center">
              <h5 className="card-title">
                <span>{t('dcaSimulator.dcaInfo.definition.title')}</span>
              </h5>
            </div>

            <div className="mb-3">
              <Trans i18nKey="dcaSimulator.dcaInfo.definition.description1" />
            </div>

            <div className="mb-3">
              <Trans i18nKey="dcaSimulator.dcaInfo.definition.description2" />
            </div>

            <div className="mb-3">
              <Trans i18nKey="dcaSimulator.dcaInfo.definition.description3" />
            </div>
            <a
              className="text-center text-md-start text-decoration-none"
              href={t('dcaSimulator.dcaInfo.definition.link')}
              target="_blank"
              rel="noreferrer"
            >
              {t('dcaSimulator.dcaInfo.definition.moreInfos')}
            </a>
          </div>
        </div>
      </div>

      <div className="col-12 col-sm-7 mt-sm-0 mt-3 d-flex">
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-center">
              <h5 className="card-title">
                <span>{t('dcaSimulator.dcaInfo.help.title')}</span>
              </h5>
            </div>

            <div className="mb-3">
              <Trans i18nKey="dcaSimulator.dcaInfo.help.description1" />
            </div>

            <div className="mb-3">
              <Trans i18nKey="dcaSimulator.dcaInfo.help.description2" />
            </div>

            <div className="mb-3">
              <Trans i18nKey="dcaSimulator.dcaInfo.help.description3" />
            </div>

            <div>
              <Trans i18nKey="dcaSimulator.dcaInfo.help.description4" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DcaInfo;
