import React, { Component, Fragment } from 'react';
import Select, { SingleValue } from 'react-select';
import {
  Link,
  withTranslation,
  WithTranslation,
} from 'gatsby-plugin-react-i18next';

/** Components */
import { BlockLoader } from '@components/utils/block-loader';
import DcaSimulator from '@components/dca-simulator/dca-simulator';

/** Models */
import { DCAInterval } from '@models/dca-interval.enum';
import { DcaPairs } from '@models/request-get-dca-pairs.model';
import { SelectOption } from '@models/select-option.model';

/** Root */
import { ApiExchange } from '@root/exchanges/api.exchange';
import { Exchange as RootExchange } from '@root/exchange';
import { orderAlphabetically } from '@root/util';

/** Services */
import { DCAService } from '@services/dca.service';

type State = {
  loading: boolean;
  allDcaPairs: DcaPairs | undefined;
  baseTokens: SelectOption[];
  selectedBaseToken: SelectOption | null;
  amount: number;
  selectedRecurrence: SelectOption | null;
  recurrences: SelectOption[];
  minAmount: number;
  maxAmount: number;
};

class AddDcaComponent extends Component<WithTranslation, State> {
  private readonly dcaService = new DCAService();
  private readonly defaultQuoteToken = 'USDT';

  private apiExchange: ApiExchange | null = null;

  constructor(props: WithTranslation) {
    super(props);

    const recurrences = orderAlphabetically(
      Object.values<string>(DCAInterval),
    ).map((value: string) => ({
      value,
      label: props.t(`dcaSimulator.${value}`),
    }));

    this.state = {
      recurrences,
      loading: true,
      allDcaPairs: undefined,
      baseTokens: [],
      selectedBaseToken: null,
      amount: 50,
      selectedRecurrence: recurrences[1],
      minAmount: 1,
      maxAmount: 10000000,
    };
  }

  async componentDidMount(): Promise<void> {
    await RootExchange.init();
    this.apiExchange = RootExchange.get();
    await this.getDcaPairs();

    this.onSelectExchange();
  }

  private onRecurrenceDidChange(selectOption: SelectOption | null): void {
    if (!selectOption) {
      return;
    }
    this.setState({ selectedRecurrence: selectOption });
  }

  private async onSelectExchange(): Promise<void> {
    const { allDcaPairs } = this.state;

    const baseTokens = allDcaPairs?.tokenList ? allDcaPairs?.tokenList : [];

    this.filterBaseTokens(baseTokens, this.defaultQuoteToken);
    this.autoSelectBaseToken();

    this.setState({
      loading: false,
    });
  }

  private async getDcaPairs(): Promise<void> {
    const allDcaPairs = await this.dcaService.getDcaPairs();
    this.setState({ allDcaPairs });
  }

  private filterBaseTokens(
    baseTokens: string[],
    selectedQuoteToken: string,
  ): void {
    const { allDcaPairs } = this.state;

    const results = baseTokens
      .filter((base: string) => {
        const market =
          this.apiExchange?.markets[`${base}/${selectedQuoteToken}`];
        return market && market.active;
      })
      .map((token: string) => {
        const fullName =
          allDcaPairs && allDcaPairs.mapSymbolName
            ? allDcaPairs.mapSymbolName[token]
            : '';
        const label = `${fullName} (${token})`;
        return { value: token, label };
      });

    this.setState({ baseTokens: results });
  }

  private autoSelectBaseToken(): void {
    const { baseTokens } = this.state;
    if (baseTokens.length < 1) {
      return;
    }

    const [newSelected] = baseTokens;
    this.setState({
      selectedBaseToken: newSelected,
    });
  }

  private renderConfigureDcaCard(): JSX.Element {
    const { t } = this.props;
    const {
      amount,
      loading,
      baseTokens,
      selectedBaseToken,
      selectedRecurrence,
      recurrences,
      minAmount,
      maxAmount,
    } = this.state;

    return (
      <div className="col-12 col-sm-12 col-md-5 col-xl-3 mt-0 d-flex">
        <div className="card col-12">
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-center">
              <h5 className="card-title">
                <span>{t('dcaSimulator.addDca.configureDca.title')}</span>
              </h5>
            </div>

            {loading ? (
              <BlockLoader />
            ) : (
              <Fragment>
                <div className="row mt-4">
                  <span>
                    {t('dcaSimulator.addDca.configureDca.selectToken')}
                  </span>
                  <Select
                    className="col-12 col-sm-12"
                    classNamePrefix="react-select"
                    value={selectedBaseToken}
                    onChange={(e: SingleValue<SelectOption<string>>) =>
                      this.setState({ selectedBaseToken: e })
                    }
                    isSearchable
                    placeholder={`${t(
                      'dcaSimulator.addDca.configureDca.base',
                    )}...`}
                    options={baseTokens}
                  />
                </div>

                <div className="mt-4">
                  <span>
                    {t('dcaSimulator.addDca.configureDca.recurringAmount')}
                  </span>
                  <input
                    type="number"
                    name="tradeAmount"
                    className="form-control"
                    id="tradeAmount"
                    placeholder={t('dcaSimulator.addDca.configureDca.amount')}
                    value={amount}
                    min={minAmount}
                    max={maxAmount}
                    onChange={e =>
                      this.setState({ amount: Number(e.target.value) })
                    }
                  />
                </div>

                <div className="mt-4">
                  <span>
                    {t('dcaSimulator.addDca.configureDca.recurrence')}
                  </span>
                  <Select
                    className="col-12 col-sm-12"
                    classNamePrefix="react-select"
                    value={selectedRecurrence}
                    onChange={(e: SingleValue<SelectOption<string>>) =>
                      this.onRecurrenceDidChange(e)
                    }
                    isMulti={false}
                    placeholder={t(
                      'dcaSimulator.addDca.configureDca.recurrence',
                    )}
                    options={recurrences}
                  />
                </div>
              </Fragment>
            )}

            <div className="text-center mt-5">
              <p className="mt-5">
                {t('dcaSimulator.addDca.configureDca.startYourFirstDca')}
              </p>
              <Link
                className="btn btn-primary text-body ps-5 pe-5 fw-bold"
                to="/register"
              >
                {t('navbar.register')}
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render(): JSX.Element {
    const { amount, selectedBaseToken, selectedRecurrence } = this.state;

    return (
      <div className="row mt-4 d-flex">
        {this.renderConfigureDcaCard()}

        <DcaSimulator
          base={selectedBaseToken?.value}
          quote={this.defaultQuoteToken}
          interval={
            selectedRecurrence?.value
              ? (selectedRecurrence?.value as DCAInterval)
              : DCAInterval.Monthly
          }
          amount={amount}
        />
      </div>
    );
  }
}

const AddDca = withTranslation()(AddDcaComponent);

export default AddDca;
