import Select from 'react-select';
import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'gatsby-plugin-react-i18next';
import {
  Chart,
  LineElement,
  PointElement,
  CategoryScale,
  LineController,
  LinearScale,
  Legend,
  Tooltip,
  ChartOptions,
  ChartData,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

/** Components */
import { BlockLoader } from '@components/utils/block-loader';

/** Model */
import { ExchangeType } from '@models/exchange-type.enum';
import { SelectOption } from '@models/select-option.model';
import { DCAInterval } from '@models/dca-interval.enum';
import { DcaSimulatorPeriod } from '@models/dca-simulator-periods.enum';
import { ResponseSimulatorDCA } from '@models/request/response-dca-simulator.model';
import { DcaType } from '@models/dca-type.enum';
import { BuyingPoint } from '@models/request/response-dca-simulation-result.model';

/** Root */
import { TimeProvider } from '@root/time-provider';

/** Service */
import { DCAService } from '@services/dca.service';

Chart.register(
  LineElement,
  PointElement,
  CategoryScale,
  LineController,
  LinearScale,
  Legend,
  Tooltip,
);

type State = {
  loading: boolean;
  simulationResult?: ResponseSimulatorDCA;
  investPeriods: SelectOption<string>[];
  selectedInvestPeriod: SelectOption<string>;
  chartData: ChartData<'line', number[], string>;
  aspectRatio: number;
};

type Props = WithTranslation & {
  base: string | undefined;
  quote: string | undefined;
  interval: DCAInterval;
  amount: number;
};

class DcaSimulatorComponent extends Component<Props, State> {
  private readonly dcaService: DCAService;
  private readonly defaultAspectRatio = 3.1;
  private readonly chartOptions: ChartOptions<'line'> = {
    aspectRatio: this.defaultAspectRatio,
    onResize: (chart: Chart, size: { width: number; height: number }) => {
      if (size.width < 300) {
        this.setState({ aspectRatio: 0.5 });
      } else if (size.width < 500) {
        this.setState({ aspectRatio: 0.75 });
      } else if (size.width < 900) {
        this.setState({ aspectRatio: 1.5 });
      } else {
        this.setState({ aspectRatio: this.defaultAspectRatio });
      }
    },
    plugins: {
      tooltip: {
        displayColors: false,
      },
    },
  };

  constructor(props: Props) {
    super(props);

    const investPeriods = [
      {
        value: 'max',
        label: props.t('dcaSimulator.addDca.simulator.investPeriod.max'),
      },
      {
        value: '1b',
        label: props.t('dcaSimulator.addDca.simulator.investPeriod.1month'),
      },
      {
        value: '3b',
        label: props.t('dcaSimulator.addDca.simulator.investPeriod.3month'),
      },
      {
        value: '6b',
        label: props.t('dcaSimulator.addDca.simulator.investPeriod.6month'),
      },
      {
        value: '1y',
        label: props.t('dcaSimulator.addDca.simulator.investPeriod.1year'),
      },
      {
        value: '2y',
        label: props.t('dcaSimulator.addDca.simulator.investPeriod.2years'),
      },
    ];

    this.state = {
      loading: false,
      investPeriods,
      selectedInvestPeriod: investPeriods[0],
      chartData: { labels: [], datasets: [] },
      aspectRatio: this.defaultAspectRatio,
    };

    this.dcaService = new DCAService();
  }

  async componentDidUpdate(previousProps: Props): Promise<void> {
    const { interval, base, quote, amount } = this.props;

    if (
      previousProps.interval !== interval ||
      previousProps.base !== base ||
      previousProps.quote !== quote ||
      previousProps.amount !== amount
    ) {
      this.getSimulationResult();
    }
  }

  private computeChartOptions(): ChartOptions<'line'> {
    const { aspectRatio } = this.state;
    this.chartOptions.aspectRatio = aspectRatio;
    return this.chartOptions;
  }

  private handleInvestPeriodChange(selectedOption: SelectOption<string>): void {
    this.setState({ selectedInvestPeriod: selectedOption }, () =>
      this.getSimulationResult(),
    );
  }

  private buildChart(): void {
    const dayjs = TimeProvider.getDayJs();
    const { simulationResult } = this.state;
    const { interval } = this.props;

    if (!simulationResult) {
      return;
    }

    const smart = simulationResult.smart.buyingPoints.map(
      (buyingPoint: { cumulatedValue: number }) => buyingPoint.cumulatedValue,
    );
    const classic = simulationResult.classic.buyingPoints.map(
      (buyingPoint: { cumulatedValue: number }) => buyingPoint.cumulatedValue,
    );
    const btfd = simulationResult.btfd.buyingPoints.map(
      (buyingPoint: { cumulatedValue: number }) => buyingPoint.cumulatedValue,
    );

    const labels = simulationResult.classic.buyingPoints.map(
      (buyingPoint: { timestamp: number }) => {
        const date = dayjs(buyingPoint.timestamp);

        if (interval === DCAInterval.Monthly) {
          if (date.month() === 0) {
            return String(date.year());
          }
          return date.format('MMM');
        }

        if (interval === DCAInterval.Weekly) {
          if (date.week() === date.endOf('month').week()) {
            if (date.month() === 0) {
              return String(date.year());
            }

            return date.format('MMM');
          }

          return '';
        }

        if (
          date.week() === date.startOf('month').week() &&
          date.day() === date.startOf('month').day()
        ) {
          if (date.month() === 0) {
            return String(date.year());
          }

          return date.format('MMM');
        }

        return '';
      },
    );
    labels.splice(
      labels.length - 1,
      1,
      this.props.t('dcaSimulator.addDca.simulator.now'),
    );

    const style = getComputedStyle(document.body);
    const classicColor = style.getPropertyValue('--bs-primary');
    const smartColor = style.getPropertyValue('--bs-cyan');
    const btfdColor = style.getPropertyValue('--bs-green');

    const data = {
      labels,
      datasets: [
        {
          label: this.props.t('home.dca.classic'),
          data: classic,
          fill: false,
          borderColor: classicColor,
          backgroundColor: classicColor,
          tension: 0.1,
        },
        {
          label: this.props.t('home.dca.smart'),
          data: smart,
          fill: false,
          borderColor: smartColor,
          backgroundColor: smartColor,
          tension: 0.1,
        },
        {
          label: this.props.t('home.dca.smartDip'),
          data: btfd,
          fill: false,
          borderColor: btfdColor,
          backgroundColor: btfdColor,
          tension: 0.1,
        },
      ],
    };
    this.setState({ chartData: data });
  }

  private async getSimulationResult(): Promise<void> {
    const { base, quote, interval, amount } = this.props;
    const { selectedInvestPeriod } = this.state;
    const pair = base && quote ? `${base}/${quote}` : undefined;

    if (!pair) {
      return;
    }

    this.setState({ loading: true });

    const requestSimulatorDca = {
      amount,
      exchangeName: ExchangeType.Binance,
      investPeriod: selectedInvestPeriod.value as DcaSimulatorPeriod,
      interval,
      pair,
    };
    const result = await this.dcaService.getDcaSimulatorResult(
      requestSimulatorDca,
    );

    if (!result.result) {
      this.setState({ loading: false });

      return;
    }

    this.setState(
      {
        simulationResult: result.data,
        loading: false,
      },
      () => {
        this.buildChart();
      },
    );
  }

  private getLastBuyingPointDate(type: DcaType): string | null {
    const { simulationResult } = this.state;

    if (!simulationResult) {
      return '';
    }

    const dayjs = TimeProvider.getDayJs();
    let buyingPoint: BuyingPoint;

    if (type === DcaType.Classic) {
      const buyingPointList = simulationResult.classic.buyingPoints;

      buyingPoint = buyingPointList[buyingPointList.length - 2];
    } else if (type === DcaType.Smart) {
      const buyingPointList = simulationResult.smart.buyingPoints;

      buyingPoint = buyingPointList[buyingPointList.length - 2];
    } else {
      const buyingPointList = simulationResult.btfd.buyingPoints;

      buyingPoint = buyingPointList[buyingPointList.length - 2];
    }

    if (!buyingPoint) {
      return null;
    }

    return dayjs(buyingPoint.timestamp).format('L');
  }

  render(): JSX.Element {
    const {
      loading,
      investPeriods,
      selectedInvestPeriod,
      simulationResult,
      chartData,
    } = this.state;
    const classicTotalCost = simulationResult?.classic.totalCost as number;
    const classicTotalValue = simulationResult?.classic.totalValue as number;
    const classicEvolution = simulationResult?.classic.evolution as number;
    const smartTotalCost = simulationResult?.smart.totalCost as number;
    const smartTotalValue = simulationResult?.smart.totalValue as number;
    const smartEvolution = simulationResult?.smart.evolution as number;
    const btfdTotalCost = simulationResult?.btfd.totalCost as number;
    const btfdTotalValue = simulationResult?.btfd.totalValue as number;
    const btfdEvolution = simulationResult?.btfd.evolution as number;

    return (
      <div className="col-12 col-sm-12 col-md-7 col-xl-9 mt-3 mt-sm-3 mt-md-0 d-flex">
        <div className="card col-12">
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-center">
              <h5 className="card-title">
                <span>
                  {this.props.t('dcaSimulator.addDca.simulator.title')}
                </span>
              </h5>
            </div>

            <div className="row">
              <div className="col-12">
                <span className="mt-2 me-2">
                  {this.props.t(
                    'dcaSimulator.addDca.simulator.accumulateSince',
                  )}
                </span>
                <Select
                  className="mt-0 w-25 d-inline-block"
                  classNamePrefix="react-select"
                  value={selectedInvestPeriod}
                  onChange={e =>
                    this.handleInvestPeriodChange(e as SelectOption<string>)
                  }
                  options={investPeriods}
                />
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-12">
                {loading ? (
                  <BlockLoader />
                ) : (
                  <Line options={this.computeChartOptions()} data={chartData} />
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="card mt-3">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12 col-sm-4">
                        <span className="col-12 d-flex justify-content-center align-items-center">
                          <span className="me-2 chart-legend bg-primary" />
                          {this.props.t(
                            'dcaSimulator.addDca.simulator.classic',
                          )}
                        </span>
                        {loading ? (
                          <BlockLoader />
                        ) : (
                          <div>
                            <div className="mb-0 col-12 d-flex justify-content-center">
                              <span className="me-1">
                                {this.props.t(
                                  'dcaSimulator.addDca.simulator.totalCost',
                                )}
                              </span>
                              <strong>{classicTotalCost}$</strong>
                            </div>
                            <div className="mb-0 col-12 d-flex justify-content-center">
                              <span className="me-1">
                                {this.props.t(
                                  'dcaSimulator.addDca.simulator.totalValue',
                                )}
                              </span>
                              <strong>{classicTotalValue}$</strong>
                            </div>
                            <div className="mb-0 col-12 d-flex justify-content-center">
                              <span className="me-1">
                                {this.props.t(
                                  'dcaSimulator.addDca.simulator.evolution',
                                )}
                              </span>
                              <strong>{classicEvolution}%</strong>
                            </div>
                            {this.getLastBuyingPointDate(DcaType.Classic) ? (
                              <div className="mb-0 col-12 d-flex justify-content-center">
                                <span className="me-1">
                                  {this.props.t(
                                    'dcaSimulator.addDca.simulator.lastBuyingPoint',
                                  )}
                                </span>
                                <strong>
                                  {this.getLastBuyingPointDate(DcaType.Classic)}
                                </strong>
                              </div>
                            ) : null}
                          </div>
                        )}
                      </div>
                      <div className="col-12 col-sm-4">
                        <span className="col-12 d-flex justify-content-center align-items-center">
                          <span className="me-2 chart-legend bg-cyan" />
                          {this.props.t('dcaSimulator.addDca.simulator.smart')}
                        </span>
                        {loading ? (
                          <BlockLoader />
                        ) : (
                          <div>
                            <div className="mb-0 col-12 d-flex justify-content-center">
                              <span className="me-1">
                                {this.props.t(
                                  'dcaSimulator.addDca.simulator.totalCost',
                                )}
                              </span>
                              <strong>{smartTotalCost}$</strong>
                            </div>
                            <div className="mb-0 col-12 d-flex justify-content-center">
                              <span className="me-1">
                                {this.props.t(
                                  'dcaSimulator.addDca.simulator.totalValue',
                                )}
                              </span>
                              <strong>{smartTotalValue}$</strong>
                            </div>
                            <div className="mb-0 col-12 d-flex justify-content-center">
                              <span className="me-1">
                                {this.props.t(
                                  'dcaSimulator.addDca.simulator.evolution',
                                )}
                              </span>
                              <strong>{smartEvolution}%</strong>
                            </div>
                            {this.getLastBuyingPointDate(DcaType.Smart) ? (
                              <div className="mb-0 col-12 d-flex justify-content-center">
                                <span className="me-1">
                                  {this.props.t(
                                    'dcaSimulator.addDca.simulator.lastBuyingPoint',
                                  )}
                                </span>
                                <strong>
                                  {this.getLastBuyingPointDate(DcaType.Smart)}
                                </strong>
                              </div>
                            ) : null}
                          </div>
                        )}
                      </div>
                      <div className="col-12 col-sm-4">
                        <span className="col-12 d-flex justify-content-center align-items-center">
                          <span className="me-2 chart-legend bg-success" />
                          {this.props.t('dcaSimulator.addDca.simulator.btfd')}
                        </span>
                        {loading ? (
                          <BlockLoader />
                        ) : (
                          <div>
                            <div className="mb-0 col-12 d-flex justify-content-center">
                              <span className="me-1">
                                {this.props.t(
                                  'dcaSimulator.addDca.simulator.totalCost',
                                )}
                              </span>
                              <strong>{btfdTotalCost}$</strong>
                            </div>
                            <div className="mb-0 col-12 d-flex justify-content-center">
                              <span className="me-1">
                                {this.props.t(
                                  'dcaSimulator.addDca.simulator.totalValue',
                                )}
                              </span>
                              <strong>{btfdTotalValue}$</strong>
                            </div>
                            <div className="mb-0 col-12 d-flex justify-content-center">
                              <span className="me-1">
                                {this.props.t(
                                  'dcaSimulator.addDca.simulator.evolution',
                                )}
                              </span>
                              <strong>{btfdEvolution}%</strong>
                            </div>
                            {this.getLastBuyingPointDate(DcaType.BTFD) ? (
                              <div className="mb-0 col-12 d-flex justify-content-center">
                                <span className="me-1">
                                  {this.props.t(
                                    'dcaSimulator.addDca.simulator.lastBuyingPoint',
                                  )}
                                </span>
                                <strong>
                                  {this.getLastBuyingPointDate(DcaType.BTFD)}
                                </strong>
                              </div>
                            ) : null}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const DcaSimulator = withTranslation()(DcaSimulatorComponent);

export default DcaSimulator;
