import React, { Component, Fragment } from 'react';
import { graphql } from 'gatsby';
import { withTranslation, WithTranslation } from 'gatsby-plugin-react-i18next';

/** Components */
import Seo from '@components/seo';
import DcaInfo from '@components/dca-simulator/dca-info';
import AddDca from '@components/dca-simulator/add-dca';
import MainContainer from '@components/main.container';

type State = {
  loading: boolean;
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

class DcaSimulatorComponent extends Component<WithTranslation, State> {
  constructor(props: Readonly<WithTranslation>) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  render(): JSX.Element {
    const { t } = this.props;

    return (
      <Fragment>
        <Seo description={t('seo.dcaDescription')} />
        <MainContainer classNameContainer="bg-primary">
          <div className="container-xxl pt-4 pb-4">
            <DcaInfo />
            <AddDca />
          </div>
        </MainContainer>
      </Fragment>
    );
  }
}

const DcaSimulator = withTranslation()(DcaSimulatorComponent);

export default DcaSimulator;
