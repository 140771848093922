import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import 'dayjs/locale/fr';
import i18next from 'i18next';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);
dayjs.extend(weekOfYear);

export class TimeProvider {
  static init(): void {
    dayjs.locale(i18next.language);
  }

  static getDayJs(): typeof dayjs {
    return dayjs;
  }

  static getTimeZone(): string {
    let userTimeZone = dayjs.tz.guess();

    if (userTimeZone === 'CET') {
      userTimeZone = 'Europe/Paris';
    }

    return userTimeZone;
  }
}
